import React from "react";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { useSession } from "next-auth/react";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import dayjs from "dayjs";
import Link from "@mui/material/Link";
import NextLink from "next/link";
import { listLink } from "lib/util";
const styleLeft = {
  display: "inline-block",
  maxWidth: "70%", // Adjust this value to control the width of the bubble
  padding: 2,
  margin: 1,
  color: "white",
  borderRadius: "10px",
  backgroundColor: "primary.main", // Customize the background color
  position: "relative",
  ":after": {
    content: "''",
    position: "absolute",
    left: 0,
    top: "80%",
    width: 0,
    height: 0,
    border: "22px solid transparent",
    borderRightColor: "primary.main",
    borderLeft: 0,
    borderBottom: 0,
    marginTop: "-22px",
    marginLeft: "-11px",
  },
};
const styleRight = {
  ...styleLeft,
  backgroundColor: "secondary.light",
  color: "inherit",
  ":after": {
    content: "''",
    position: "absolute",
    right: 0,
    top: "80%",
    width: 0,
    height: 0,
    border: "22px solid transparent",
    borderLeftColor: "secondary.light",
    borderRight: 0,
    borderBottom: 0,
    marginTop: "-22px",
    marginRight: "-11px",
  },
};

const ListQuote = ({ list }) => {
  return (
    <Box>
      <Stack spacing={2}>
        <Box>
          <Link>
            <NextLink href={listLink(list)}>
              <b>{list.user.name}'s</b> list{list.title ? ` ${list.title}` : ""}
            </NextLink>
          </Link>{" "}
          from <b>{dayjs(list.createdAt).format("ddd M/D")}</b>:
        </Box>
        <Box>
          {list.ListItems.map((item, index) => (
            <Stack spacing={1} key={item.id} direction="row">
              <Box>
                <b>{index + 1}.</b>{" "}
              </Box>
              <Box>{item.content}</Box>
            </Stack>
          ))}
        </Box>
      </Stack>
    </Box>
  );
};

const ChatBubble = ({ message, pending }) => {
  const { data: session } = useSession();
  // @ts-ignore
  const sessionUser: ExtendedSessionUser = session?.user;
  const left = message.senderId != sessionUser.id;
  const listQuote = message.listId ? <ListQuote list={message.list} /> : null;
  const Container = listQuote
    ? (props) => (
        <Paper sx={{ p: 2, mt: 2, mb: 2 }}>
          <Stack spacing={2}>{props.children}</Stack>
        </Paper>
      )
    : Box;
  return (
    <Stack sx={pending === true ? { opacity: 0.5 } : null}>
      <Container>
        {listQuote}
        <Stack direction="row">
          {!left && <Box flexGrow={1} />}
          <Paper elevation={3} sx={left ? styleLeft : styleRight}>
            <Typography variant="body1">{message.message}</Typography>
          </Paper>
        </Stack>
      </Container>
    </Stack>
  );
};

export default ChatBubble;
