import Button from "@mui/material/Button";
import { useSession } from "next-auth/react";
import { signIn } from "next-auth/react";
import { mutate } from "swr";
import { useState, useEffect } from "react";

const FollowButton = ({ loading, profile, mobile, alert }) => {
  const { data: session } = useSession();
  // @ts-ignore
  const sessionUser: ExtendedSessionUser = session?.user;
  const [following, setFollowing] = useState(false);

  useEffect(() => {
    if (profile?.isSubscribed || profile?.subscribers?.length > 0) {
      setFollowing(true);
    } else {
      setFollowing(false);
    }
  }, [profile]);
  function follow() {
    loading(true);
    fetch(`/api/profile/${profile.id}/follow`, {
      method: "POST",
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.error) {
          alert({ message: res.error, status: "error" });
          return;
        } else {
          alert({ message: "Followed!", status: "success" });
          setFollowing(!following);
          mutate(`/api/profile/${profile.id}`);
          return;
        }
      })
      .finally(() => {
        loading(false);
      });
  }
  if (sessionUser?.id === profile?.id) return <></>;
  return (
    <Button
      sx={{ maxHeight: "3em", mt: 2, minWidth: "7em" }}
      variant={"outlined"}
      size={mobile ? "small" : "medium"}
      onClick={
        session ? () => follow() : () => signIn(undefined, { callbackUrl: "/" })
      }
    >
      {following ? "Following" : <span>Follow&nbsp;+</span>}{" "}
    </Button>
  );
};

export default FollowButton;
