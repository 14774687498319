import Chip from "@mui/material/Chip";
import { useRouter } from "next/router";
export default function Tag(props: any) {
  const { name } = props;
  const router = useRouter();
  return (
    <Chip
      sx={{ mb: 1, mr: 1 }}
      label={name}
      onClick={() => router.push(`/tags/${name}`)}
    />
  );
}
