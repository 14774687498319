import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import dayjs from "dayjs";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
// import Badge from "@mui/material/Badge";
import Fab from "@mui/material/Fab";
import CardActions from "@mui/material/CardActions";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import Link from "@mui/material/Link";
import NextLink from "next/link";
import Grid from "@mui/material/Grid";
import MuiContainer from "@mui/material/Container";
import Avatar from "components/Avatar";
// import IconMood from "@mui/icons-material/Mood";
import IconChat from "@mui/icons-material/Chat";
import IconEdit from "@mui/icons-material/Edit";
import IconVisibility from "@mui/icons-material/Visibility";
// import IconShare from "@mui/icons-material/Share";
import { profileLink } from "lib/util";
import { useRouter } from "next/router";
import { mutate } from "swr";
import { useSession, signIn } from "next-auth/react";
import FollowButton from "./FollowButton";
import ChatModal from "./ChatModal";
import { useState } from "react";
import ListItem from "./ListItem";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import Tag from "./Tag";
import { Badge, Chip, IconButton, Tooltip } from "@mui/material";
export default function List(props) {
  const {
    list,
    mine,
    single,
    loading,
    alert,
    editList,
    apiPath,
    mobile,
    noFollowButton,
  } = props;
  const { data: session } = useSession();
  const router = useRouter();
  const [chat, setChat] = useState(false);
  const [expanded, setExpanded] = useState(single);
  const [originalPath, setOriginalPath] = useState(router.asPath);
  function edit() {
    editList(list);
  }
  function drop() {
    if (!confirm("Are you sure you want to delete this list?")) return;
    if (!mine) return;
    loading(true);
    fetch("/api/list/delete", {
      method: "POST",
      body: JSON.stringify({ id: list.id }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          alert({ status: "error", message: data.error });
        } else {
          alert({ status: "success", message: "List deleted!" });
          mutate("/api/list/mine");
        }
      })
      .finally(() => {
        loading(false);
      });
  }
  function like() {
    if (!session) {
      signIn(undefined, { callbackUrl: "/" });
    }
    if (list.Likes.length > 0) return;
    loading(true);
    fetch("/api/list/like", {
      method: "POST",
      body: JSON.stringify({ id: list.id }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          alert({ status: "error", message: data.error });
        } else {
          alert({ status: "success", message: "Liked!" });
          mutate(apiPath);
        }
      })
      .finally(() => {
        loading(false);
      });
  }
  function closeDialog() {
    setExpanded(false);
    window.history.replaceState("", "", originalPath);
  }
  function openDialog() {
    // return router.push(`/list/${listSlug}/${list.id}`);
    setExpanded(true);
    window.history.replaceState("", "", `/list/${listSlug}/${list.id}`);
    fetch(`/api/list/viewed/${list.id}`);
  }

  const listTitle = list?.title || dayjs(list?.createdAt).format("ddd M/D");
  const listSlug = listTitle.replace(/[^a-zA-Z0-9]/g, "-").toLowerCase();
  const isModal = expanded && !single;
  const Container = isModal
    ? (props) => (
        <Dialog {...props}>
          <MuiContainer maxWidth="md">{props.children}</MuiContainer>
        </Dialog>
      )
    : Box;
  return (
    <Container
      open={true}
      scroll="body"
      fullScreen={true}
      onClose={() => closeDialog()}
      sx={mobile && isModal ? { pb: 6, zIndex: "1075" } : {}}
    >
      {isModal && (
        <IconButton
          size="small"
          color="primary"
          sx={{ position: "fixed", right: 20, top: 20 }}
          onClick={() => closeDialog()}
        >
          <CloseIcon />
        </IconButton>
      )}
      <Card sx={isModal ? { boxShadow: "none", pt: 8 } : { cursor: "pointer" }}>
        <CardContent>
          <Stack direction="row" spacing={2}>
            <Avatar src={list.user.image} />
            <Typography variant="h5" flexGrow={1}>
              <Link onClick={() => openDialog()}>
                {/* <NextLink href={}> */}
                {listTitle}
                {/* </NextLink> */}
              </Link>{" "}
              by{" "}
              <div>
                <Link>
                  <NextLink href={profileLink(list.user)}>
                    {list.user.name}
                  </NextLink>
                </Link>
              </div>
              <Typography variant="body2">
                {dayjs(list.createdAt).format("ddd M/D")}
              </Typography>
              {expanded && (
                <Typography variant="body2">{list.description}</Typography>
              )}
            </Typography>
            {!noFollowButton && (
              <FollowButton
                loading={loading}
                alert={alert}
                mobile={mobile}
                profile={list.user}
              />
            )}
            {mine && (
              <Stack
                direction={mobile ? "column" : "row"}
                alignItems={"center"}
                spacing={2}
              >
                <Fab onClick={edit} size="small">
                  <IconEdit />
                </Fab>
                <Fab onClick={drop} size="small" color="error">
                  <DeleteIcon />
                </Fab>
                <Tooltip
                  title={`This list was read ${list.totalViews} time${
                    list.totalViews === 1 ? "" : "s"
                  }`}
                  enterTouchDelay={1}
                  placement="top"
                >
                  <Chip
                    icon={<IconVisibility />}
                    size="small"
                    color="primary"
                    sx={{ cursor: "default" }}
                    label={list.totalViews || "0"}
                  />
                </Tooltip>
              </Stack>
            )}
          </Stack>

          <Stack
            sx={expanded && { mx: { xs: 0, sm: "24px" } }}
            onClick={() => openDialog()}
            spacing={6}
            margin={3}
            mt={6}
          >
            {list.ListItems.map((item, index) => (
              <ListItem
                {...props}
                key={item.id}
                expanded={expanded}
                index={index}
                item={item}
              />
            ))}
            {!expanded && <div>more...</div>}
          </Stack>
        </CardContent>
        <CardActions>
          <Grid container p={2}>
            <Grid item xs={10}>
              {list.Tags?.map((tag) => (
                <Tag key={tag.id} name={tag.name} />
              ))}
            </Grid>
            <Grid item xs={2}>
              <Fab size="small" sx={{ float: "right" }} color="primary">
                <IconChat onClick={() => setChat(true)} />
              </Fab>
              {/* {/* <Fab size="small">
                <IconShare />
              </Fab> 
            <Badge
              sx={{ float: "right" }}
              badgeContent={list._count.Likes || "0"}
              color="primary"
            >
              <Fab
                disabled={list.Likes.length > 0}
                color="secondary"
                size="small"
                onClick={like}
              >
                <IconMood />
              </Fab>
            </Badge> */}
            </Grid>
          </Grid>
        </CardActions>
        {chat && (
          <ChatModal
            {...props}
            recipient={list.user}
            closeCallback={() => setChat(false)}
            list={list}
          />
        )}
      </Card>
    </Container>
  );
}
