import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import IconSend from "@mui/icons-material/Send";
import IconButton from "@mui/material/IconButton";
import IconClose from "@mui/icons-material/Close";
import useSWR from "swr";
import fetcher from "lib/fetcher";
import { useState } from "react";
import { mutate } from "swr";
import ChatBubble from "./ChatBubble";
import { useEffect, useRef } from "react";
import { useSession } from "next-auth/react";

export default function ChatModal(props) {
  const { recipient, closeCallback, loading, alert, list } = props;
  const [message, setMessage] = useState("");
  const [firstMessage, setFirstMessage] = useState(true);
  const [pendingMessage, setPendingMessage] = useState(null);
  const { data: session } = useSession();
  // @ts-ignore
  const sessionUser: ExtendedSessionUser = session?.user;
  const apiURL = `/api/chat/get/${recipient.id}`;
  const { data: chats, error, isLoading } = useSWR(apiURL, fetcher);
  function send() {
    setPendingMessage(message);
    setTimeout(scrollToBottom, 250);
    fetch(`/api/chat/send/${recipient.id}`, {
      method: "POST",
      body: JSON.stringify({
        message,
        listId: firstMessage && list ? list?.id : null,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          alert({ status: "error", message: data.error });
        } else {
          setMessage("");
          setFirstMessage(false);
          scrollToBottom();
          mutate(apiURL);
        }
      })
      .finally(() => {
        setPendingMessage(null);
      });
  }
  function scrollToBottom() {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  }
  const scrollRef = useRef(null);
  useEffect(() => {
    setTimeout(scrollToBottom, 250);
    //scrollRef.current.scrollIntoView({ behaviour: "smooth" });
  }, [isLoading, chats?.length]);

  useEffect(() => {
    if (chats?.length)
      setTimeout(() => {
        mutate("/api/chat/conversations");
        mutate("/api/chat/unread");
      }, 500);
  }, [chats?.length]);

  return (
    <Dialog open={true} onLoad={scrollToBottom} fullWidth>
      <DialogTitle>
        Chat with {recipient.name}
        <IconButton
          aria-label="close"
          onClick={closeCallback}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <IconClose />
        </IconButton>
      </DialogTitle>
      <DialogContent ref={scrollRef} sx={{ overflowY: "auto" }}>
        {Array.isArray(chats) &&
          chats?.map((chat) => (
            <ChatBubble pending={false} message={chat}></ChatBubble>
          ))}
        {pendingMessage && (
          <ChatBubble
            message={{ message: pendingMessage, senderId: sessionUser.id }}
            pending={true}
          />
        )}
      </DialogContent>
      <DialogActions>
        <TextField
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          fullWidth
          autoFocus
          multiline
        />
        <IconButton onClick={() => send()} color="primary">
          <IconSend />
        </IconButton>
      </DialogActions>
    </Dialog>
  );
}
