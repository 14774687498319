import {
  Grid,
  Stack,
  Typography,
  Badge,
  Button,
  IconButton,
  Link,
} from "@mui/material";
import ListImage from "./ListImage";
import IconBookmark from "@mui/icons-material/Bookmark";
import IconBookmarkBorder from "@mui/icons-material/BookmarkBorder";
// import IconMore from "@mui/icons-material/ExpandMore";
// import IconLess from "@mui/icons-material/ExpandLess";
import NextLink from "next/link";
import { mutate } from "swr";
import { useSession, signIn } from "next-auth/react";
import { urlSeparator } from "lib/constants";
import { useRef } from "react";
// import { useState } from "react";
export default function ListItem(props) {
  const {
    item,
    index,
    expanded,
    mine,
    loading,
    apiPath,
    alert,
    mobile,
    single,
  } = props;
  const { data: session } = useSession();
  const snippetLength = mobile ? 50 : 300;
  const containerRef = useRef(null);
  // const [expanded, setExpanded] = useState(false);
  function bookmark(listItemId) {
    if (!session) {
      signIn(undefined, { callbackUrl: "/" });
    }
    loading(true);
    fetch("/api/listitem/bookmark/create", {
      method: "POST",
      body: JSON.stringify({ id: listItemId }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          alert({ status: "error", message: data.error });
        } else {
          alert({ status: "success", message: "Bookmarked!" });
          mutate(apiPath);
        }
      })
      .finally(() => {
        loading(false);
      });
  }
  const hasMoreContent =
    !single &&
    (item?.description?.length > snippetLength ||
      item?.url?.length > 0 ||
      item?.images?.length > 0);
  const snippet =
    !single && item?.description?.length > snippetLength
      ? item?.description?.substring(0, snippetLength) + "..."
      : item?.description;
  return (
    <>
      <Grid container key={item?.id}>
        <Grid item xs={11}>
          <Stack>
            <Badge
              color="primary"
              badgeContent={index + 1}
              anchorOrigin={{ vertical: "top", horizontal: "left" }}
            >
              <Typography variant="h6" paddingLeft={"1em"} marginTop={-2}>
                {item.content}
              </Typography>
            </Badge>
            {item.description && (
              <Typography
                variant="body2"
                mb={2}
                paddingLeft={"1.5em"}
                ref={containerRef}
                sx={
                  !expanded
                    ? {
                        fontStyle: "italic",
                        // textOverflow: "ellipsis",
                        overflow: "hidden",
                        // whiteSpace: "nowrap",
                        display: "-webkit-box",
                        "-webkit-box-orient": "vertical",
                        "-webkit-line-clamp": mobile ? "2" : "1",
                      }
                    : {
                        lineHeight: "2em",
                        pl: { xs: 0, sm: "1.5em" },
                        textAlign: "justify",
                      }
                }
              >
                {item.description?.split("\n").map((d) => (
                  <p>{d}</p>
                ))}
              </Typography>
            )}
            {expanded &&
              item.url &&
              item.url.split(urlSeparator).map((url) => (
                <Typography
                  variant="body2"
                  pb={2}
                  paddingLeft={"1.5em"}
                  sx={{
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "no-wrap",
                  }}
                >
                  <Link>
                    <NextLink target="_blank" href={url}>
                      {url}
                    </NextLink>
                  </Link>
                </Typography>
              ))}
            {expanded && (
              <Grid item xs={12}>
                {item?.images?.map((i) => (
                  <ListImage
                    width={containerRef.current?.clientWidth || 200}
                    src={i?.url}
                  />
                ))}
              </Grid>
            )}
            {/* <Stack
              direction="row"
              justifyContent={!mobile ? "flex-start" : "flex-end"}
              mt={2}
              mb={2}
            >
              {hasMoreContent && (
                <Button
                  onClick={() => setExpanded(!expanded)}
                  endIcon={expanded ? <IconLess /> : <IconMore />}
                >
                  <Typography variant="body2">
                    {expanded ? "" : "More"}
                  </Typography>
                </Button>
              )}
            </Stack> */}
          </Stack>
        </Grid>
        {(expanded || mine) && (
          <Grid item xs={1}>
            <Stack>
              <Badge
                badgeContent={item?._count?.bookmarks || null}
                color="primary"
                sx={{ opacity: 0.4, ":hover": { opacity: 1 } }}
              >
                <IconButton onClick={() => bookmark(item.id)} sx={{ mr: -0.5 }}>
                  {item.bookmarks?.length > 0 ? (
                    <IconBookmark />
                  ) : (
                    <IconBookmarkBorder />
                  )}
                </IconButton>
              </Badge>
            </Stack>
          </Grid>
        )}
      </Grid>
    </>
  );
}
